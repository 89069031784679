type THeroes =
|'ana'
|'ashe'
|'baptiste'
|'bastion'
|'bob'
|'cassidy'
|'brigitte'
|'dva'
|'doomfist'
|'echo'
|'freja'
|'genji'
|'hanzo'
|'hazard'
|'kiriko'
|'illari'
|'junkrat'
|'junkerQueen'
|'junker_queen'
|'juno'
|'lucio'
|'lifeweaver'
|'mauga'
|'mei'
|'mccree'
|'mercy'
|'moira'
|'orisa'
|'payload'
|'pharah'
|'ramattra'
|'reaper'
|'reinhardt'
|'roadhog'
|'sigma'
|'sojourn'
|'soldier76'
|'soldier_76'
|'sombra'
|'symmetra'
|'torbjorn'
|'tracer'
|'venture'
|'widowmaker'
|'winston'
|'wreckingBall'
|'wrecking_ball'
|'zarya'
|'zenyatta'
|'status.hack.name'
|'status.hack.verb'
|'status.stunned.name'
|'status.stunned.verb'
|'status.frozen.name'
|'status.frozen.verb'
|'status.sleep.name'
|'status.sleep.verb'
|'status.antiHeal.name'
|'status.antiHeal.verb'
|'status.mechKill.name'
|'status.mechKill.verb'
|'status.unknown.name'
|'status.unknown.verb'
|'ults.nanoBoost'
|'ults.amplificationmatrix'
|'ults.bob'
|'ults.configurationtank'
|'ults.rally'
|'ults.meteorstrike'
|'ults.selfdestruct'
|'ults.dragonblade'
|'ults.dragonstrike'
|'ults.riptire'
|'ults.soundbarrier'
|'ults.deadeye'
|'ults.duplicate'
|'ults.blizzard'
|'ults.valkyrie'
|'ults.coalescence'
|'ults.supercharger'
|'ults.barrage'
|'ults.deathblossom'
|'ults.earthshatter'
|'ults.wholehog'
|'ults.tacticalvisor'
|'ults.emp'
|'ults.photonbarrier'
|'ults.moltencore'
|'ults.pulsebomb'
|'ults.infrasight'
|'ults.primalrage'
|'ults.minefield'
|'ults.gravitonsurge'
|'ults.graviticflux'
|'ults.transcendence'
;
const HeroesNS = 'heroes';
function HeroesF(_: THeroes): string { return `${HeroesNS}:${_}`; }
export {HeroesF,HeroesNS,THeroes};

import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { DrawingCanvasEvent, DrawingTool, SerializedObject } from '@insights-gaming/drawing-canvas';
import { VideoReplayContext } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CreateIcon from '@material-ui/icons/Create';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import RedoIcon from '@material-ui/icons/Redo';
import RemoveIcon from '@material-ui/icons/Remove';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import UndoIcon from '@material-ui/icons/Undo';
import classNames from 'classnames';
import DraggableContainer from 'components/draggable-container/DraggableContainer';
import { EIntercomID } from 'constants/strings';
import { enterKeybindModeAC, exitKeybindModeAC } from 'features/keybinding/keybinding-slice';
import { desktop } from 'features/media-queries';
import { MobileDrawingToolbarContext } from 'features/mobile-drawing-toolbar/MobileDrawingToolbarContext';
import { useKeybindings } from 'hooks/useKeybindings';
import { KeyCommand } from 'keybindings/keybindings';
import debounce from 'lodash/debounce';
import ShortcutDialogContent from 'material/dialogs/shortcut-dialog-content/ShortcutDialogContent';
import { ShortcutDialogContext } from 'material/dialogs/shortcut-dialog-content/ShortcutDialogContext';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import FAIcon from 'subcomponents/fa-icon/FAIcon';

import { useIsDesktop } from '../../../features/media-queries/hooks';
import { VideoPlayerContext } from '../VideoPlayerInterface';
import DrawingOverlay, { DrawingOverlayProps } from './DrawingOverlay';
import DrawingToolbar, { action, ButtonDefinition, colour, compnt, composite, tooool } from './DrawingToolbar';
import DrawingToolbarColorPickerButton from './DrawingToolbarColorPickerButton';
import DrawingToolbarMapButton from './DrawingToolbarMapButton';
import DrawingToolbarSizePickerButton from './DrawingToolbarSizePickerButton';
import DrawingToolbarStickerButton from './DrawingToolbarStickerButton';
import { EToolSize } from './useDrawingOverlayController';
import { bindDrawingOverlay, DrawingState } from './useDrawingState';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    '&$isYoutubeVideo': {
      pointerEvents: 'none',
    },
  },
  toolbarContainer: {
    position: 'relative',
    zIndex: 2,
    float: 'right',
    transition: 'opacity .3s, transform .3s',
    '&$dimmed': {
      opacity: 0,
    },
    '&$hidden': {
      opacity: 0,
      transform: 'translateY(-100%)',
      pointerEvents: 'none',
    },
  },
  toolbar: {
    [desktop(theme)]: {
      padding: theme.spacing(0, 1),
    },
    '&$grabbable': {
      cursor: 'grab',
    },
    '&$grabbing': {
      cursor: 'grabbing',
    },
  },
  picker: {
    border: 'none',
    background: 'transparent',
    padding: 0,
    width: '3em',
    cursor: 'pointer',
  },
  selected: {
    color: theme.palette.primary.main,
  },
  canvas: {
    top: 0,
    left: 0,
  },
  bar: {
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    marginRight: theme.spacing(1),
  },
  dimmed: {},
  hidden: {},
  grabbable: {},
  grabbing: {},
  isYoutubeVideo: {},
}));

interface VideoPlayerDrawingOverlayOwnProps {
  state: DrawingState;

  controllable?: boolean;
  immutable?: boolean;
  hideToolbar?: boolean;
  alternateToolBarLocation?: HTMLDivElement | null;
  canvasVisible?: boolean;
  disableVideoControl?: boolean;
  isYoutubeVideo?: boolean;

  initialTool?: DrawingTool;
  initialColor?: string;
  initialSize?: EToolSize;
  initialObjects?: SerializedObject[];

  onCanvasCleared?: (e: DrawingCanvasEvent) => void;
  onCanvasEnabled?: (visible: boolean) => void;
}

export type VideoPlayerDrawingOverlayProps =
  & VideoPlayerDrawingOverlayOwnProps
  & Pick<DrawingOverlayProps, 'onPing' | 'onObjectsAdded' | 'onObjectsModified' | 'onObjectsRemoved'>
  & React.HTMLAttributes<HTMLDivElement>;

const mainToolbarButtonDefs: ButtonDefinition[] = [
  tooool('tools.pen',       DrawingTool.PEN,       CreateIcon,    true),
  tooool('tools.eraser',    DrawingTool.ERASER,    FAIcon,        true,    { icon: faEraser }),
  tooool('tools.select',    DrawingTool.SELECT,    OpenWithIcon,  true),
  compnt(undefined,         DrawingToolbarSizePickerButton,  true),
  colour('tools.red',       '#FF0000'),
  colour('tools.blue',      '#0000FF'),
  compnt(undefined,         DrawingToolbarColorPickerButton, true),
  composite(undefined, 'rectangle-ellipse-line-arrow', [
    tooool('tools.rectangle', DrawingTool.RECTANGLE, CropSquareIcon,       true),
    tooool('tools.ellipse',   DrawingTool.ELLIPSE,   PanoramaFishEyeIcon),
    tooool('tools.line',      DrawingTool.LINE,      RemoveIcon,           true),
    tooool('tools.arrow',     DrawingTool.ARROW,     ArrowRightAltIcon),
  ], true),
  tooool('tools.textbox',   DrawingTool.TEXTBOX,   TextFieldsIcon,       true),
  action('tools.undo',      (c) => c.undo(),  (s) => !s.canUndo,    UndoIcon),
  action('tools.redo',      (c) => c.redo(),  (s) => !s.canRedo,    RedoIcon),
  action('tools.clearall',  (c) => c.clear(), (s) => !s.hasObjects, DeleteIcon),
];

const auxToolbarButtonDefs: ButtonDefinition[] = [
  compnt('tools.map',       DrawingToolbarMapButton),
  compnt('tools.heroicons', DrawingToolbarStickerButton),
];

function VideoPlayerDrawingOverlay({
  state,
  controllable = true,
  immutable,
  hideToolbar,
  alternateToolBarLocation,
  canvasVisible,
  disableVideoControl,
  isYoutubeVideo,
  initialObjects,
  onObjectsAdded,
  onObjectsModified,
  onObjectsRemoved,
  onCanvasCleared,
  onCanvasEnabled,
  onPing,
}: VideoPlayerDrawingOverlayProps) {
  const classes = useStyles();
  const { mobileToolbarElement } = useContext(MobileDrawingToolbarContext);
  const isDesktop = useIsDesktop();
  const {
    state: {
      fullscreen,
    },
    togglePlayState,
    pause,
  } = useContext(VideoReplayContext);

  const {
    hideControls,
    toggleFullscreen,
    isFullscreen,
  } = useContext(VideoPlayerContext);

  const {
    isShortcutDialogOpen,
    openShortcutDialog,
    closeShortcutDialog,
  } = useContext(ShortcutDialogContext);

  const dispatch = useDispatch();

  const { commandEmitter } = useKeybindings();

  const onOpen = useCallback(() => {
    dispatch(enterKeybindModeAC());
    openShortcutDialog();
  }, [openShortcutDialog, dispatch]);

  const onClose = useCallback(() => {
    dispatch(exitKeybindModeAC());
    closeShortcutDialog();
  }, [closeShortcutDialog, dispatch]);

  useEffect(() => {
    if (!isFullscreen) {
      onClose();
    }
  }, [onClose, isFullscreen]);

  useEffect(() => {
    commandEmitter.addListener('command', (event: KeyCommand) => {
      switch (event) {
        case 'video.help.toggle': isShortcutDialogOpen ? onClose() : onOpen();
      }
    });
    return () => {
      commandEmitter.removeAllListeners();
    };
  }, [isShortcutDialogOpen, onClose, onOpen, commandEmitter]);

  useEffect(() => { if (state.enabled) pause?.(); }, [pause, state.enabled]);
  useEffect(() => onCanvasEnabled?.(state.enabled), [onCanvasEnabled, state.enabled]);

  useEffect(() => {
    if (controllable && canvasVisible && !state.enabled) {
      state.setTool(DrawingTool.DEFAULT);
    }
  }, [controllable, canvasVisible, state.enabled, state.tool, state]);

  useEffect(() => {
    if (immutable) {
      state.setTool(DrawingTool.NONE);
    }
  }, [immutable, state]);

  const mainToolbarButtons = useMemo(() => (
    <DrawingToolbar
    controllable={controllable}
    disabled={immutable || (!state.enabled && !controllable)}
    state={state}
    buttonDefs={mainToolbarButtonDefs}
    />
  ), [controllable, immutable, state]);

  const bar = useMemo(() => (
    <div className={classes.bar} id={controllable ? EIntercomID.DRAWING_TOOL : EIntercomID.DRAWING_TOOL_NON_HOST}>
      {isDesktop && (
        <Paper className={classes.paper}>
          <Toolbar
          className={classes.toolbar}
          variant='dense'
          disableGutters={true}
          >
            <DrawingToolbar
            controllable={controllable}
            disabled={immutable || (!state.enabled && !controllable)}
            state={state}
            buttonDefs={auxToolbarButtonDefs}
            dividers={true}
            displayClose={false}
            />
          </Toolbar>
        </Paper>
      )}
      <Paper>
        <Toolbar
        className={classes.toolbar}
        variant='dense'
        disableGutters={true}
        >
          {mainToolbarButtons}
        </Toolbar>
      </Paper>
    </div>
  ), [classes.bar, classes.paper, classes.toolbar, controllable, immutable, isDesktop, mainToolbarButtons, state]);

  const draggable = useMemo(() => (
    <DraggableContainer right={0}>
      <div
      className={classNames(
        classes.toolbarContainer,
        {
          [classes.hidden]: hideControls && fullscreen,
        },
      )}
      style={{ visibility: hideToolbar ? 'hidden' : 'unset' }}
      >
        {bar}
      </div>
    </DraggableContainer>
  ), [bar, classes.hidden, classes.toolbarContainer, fullscreen, hideControls, hideToolbar]);

  const toolbar: JSX.Element = useMemo(() => {
    if (!fullscreen) {
      if (!isDesktop && mobileToolbarElement) {
        return ReactDOM.createPortal(mainToolbarButtons, mobileToolbarElement);
      }

      if (alternateToolBarLocation) {
        return ReactDOM.createPortal(bar, alternateToolBarLocation);
      }
    }

    return draggable;
  }, [fullscreen, draggable, isDesktop, mobileToolbarElement, alternateToolBarLocation, mainToolbarButtons, bar]);

  const clickRef = useRef(0);

  const debouncedPlayPause = useMemo(() => {
    return debounce(
      () => {
        if (clickRef.current % 2) {
          togglePlayState();
        }
        clickRef.current = 0;
      },
      200,
      { leading: false, trailing: true },
    );
  }, [togglePlayState]);

  const onClick = useCallback(() => {
    clickRef.current++;
    if (disableVideoControl) {
      return;
    }
    if (controllable) {
      debouncedPlayPause();
      if (clickRef.current === 2) {
        toggleFullscreen();
        debouncedPlayPause.flush();
      };
    }
  }, [controllable, debouncedPlayPause, disableVideoControl, toggleFullscreen]);

  return (
    <div className={classNames(classes.root, { [classes.isYoutubeVideo]: isYoutubeVideo && !state.enabled })}>
      {!hideToolbar && toolbar}
      <DrawingOverlay
      onPing={onPing}
      onClick={onClick}
      onCanvasCleared={onCanvasCleared}
      initialObjects={initialObjects}
      {...bindDrawingOverlay(state, { onObjectsAdded, onObjectsModified, onObjectsRemoved })}
      />
      <Dialog
      open={isShortcutDialogOpen}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
      disablePortal={true}
      >
        <ShortcutDialogContent onClose={onClose} />
      </Dialog>
    </div>
  );
}

export default React.memo(VideoPlayerDrawingOverlay);

import { commentAddedAC, commentUpdatedAC } from 'actions/comment-actions';
import { folderAddedAC, folderDeletedAC, folderUpdatedAC, invitationAddedAC, invitationDeletedAC, invitationReceivedAC, invitationRevokedAC, memberAddedAC, memberDeletedAC, memberUpdatedAC, notificationAddedAC, notificationDeletedAC, notificationUpdatedAC, subscribeToEventsAC, subscribeToUserEventsAC, subscribeToVideoEventsAC, teamAddedAC, teamDeletedAC, teamUpdatedAC, unsubscribeToEventsAC, unsubscribeToUserEventsAC, unsubscribeToVideoEventsAC, videoAddedAC, videoUpdatedAC } from 'actions/event-actions';
import { IClient } from 'apollo/client';
import { NotificationFragment, NotificationFragment_MemberNotification } from 'apollo/fragments/types/NotificationFragment';
import { GetMembersQuery_queryMembers_edges } from 'apollo/queries/types/GetMembersQuery';
import { Events_Subscription, UserEvents_Subscription, VideoEvents_Subscription } from 'apollo/subscriptions';
import { EventsSubscription, EventsSubscription_events, EventsSubscriptionVariables } from 'apollo/subscriptions/types/EventsSubscription';
import { UserEventsSubscription, UserEventsSubscription_userEvents } from 'apollo/subscriptions/types/UserEventsSubscription';
import { VideoEventsSubscription, VideoEventsSubscription_videoEvents, VideoEventsSubscriptionVariables } from 'apollo/subscriptions/types/VideoEventsSubscription';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { fetchUnreadFolderNotificationsAC, fetchUnreadNotificationsAC, fetchUnreadTeamNotificationsAC } from 'components/settings/notifications/notifications-slice';
import { logoutAsyncAC, selfUpdatedAC } from 'features/auth/auth-slice';
import { getTeamMemberDict } from 'features/dashboard/member/dashboard-member-selector';
import { createTag2AC, deleteTagAC, updateTagAC } from 'features/dashboard/tag/dashboard-tag-slice';
import { getTeamById } from 'features/dashboard/team/dashboard-team-selector';
import { createCustomRoleAC, deleteRoleAC, updateCustomRoleAC } from 'features/dashboard/team/dashboard-team-slice';
import { getVideoById, getVideoFetchStatusById } from 'features/dashboard/video/dashboard-video-selector';
import { deleteVideoAC, fetchVideosByIdAC } from 'features/dashboard/video/dashboard-video-slice';
import { END, EventChannel, eventChannel, SagaIterator, Task } from 'redux-saga';
import { all, call, cancel, cancelled, fork, getContext, put, SagaReturnType, select, spawn, take, takeEvery } from 'redux-saga/effects';
import { Optional } from 'types';
import { ID } from 'types/pigeon';
import { ISubscriptionOptions } from 'types/saga';
import { Action } from 'typescript-fsa';

function createEventChannel(
  {client, variables}: ISubscriptionOptions<EventsSubscriptionVariables>,
): EventChannel<EventsSubscription_events | ApolloError> {
  return eventChannel<EventsSubscription_events | ApolloError>((emit) => {
    const obs = client.subscribe({
      query: Events_Subscription,
      variables,
    });
    const sub = obs.subscribe({
      next: (result: ApolloQueryResult<EventsSubscription>) => {
        emit(result.data.events);
      },
      error: (error: ApolloError) => {
        emit(error);
      },
      complete: () => {
        emit(END);
      },
    });
    return () => {
      sub.unsubscribe();
    };
  });
}

function* subscribeEvents(action: Action<EventsSubscriptionVariables>): SagaIterator {
  const { client, dispose }: IClient = yield getContext('apollo');
  const { payload: params } = action;
  const chan: EventChannel<EventsSubscription_events | ApolloError> = createEventChannel({client, variables: params});
  try {
    while (true) {
      const event: EventsSubscription_events = yield take(chan);
      try {
        yield call(handleEvent, event, params);
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    }
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
  } finally {
    if (yield cancelled()) {
      chan.close();
    }
  }
}

function* handleEvent(event: EventsSubscription_events, payload: EventsSubscriptionVariables): SagaIterator {
  const { teamId } = payload;
  switch (event.__typename) {
    case 'VideoAddedEvent':
      yield put(videoAddedAC(event.videoAdded));
      break;
    case 'VideoUpdatedEvent':
      yield put(videoUpdatedAC(event.videoUpdated));
      break;
    case 'VideoDeletedEvent':
      yield put(deleteVideoAC.done({
        params: {id: event.videoDeleted},
        result: {__typename: 'DeleteVideoPayload', videoId: event.videoDeleted},
      }));
      break;
    case 'TagAddedEvent': {
      if (teamId) {
        const { name, color, type } = event.tagAdded;
        yield put(createTag2AC.done({
          params: {teamId, name, color, type},
          result: {__typename: 'CreateTag2Payload', tag: event.tagAdded},
        }));
      }
      break;
    }
    case 'TagUpdatedEvent': {
      const { id, name, color } = event.tagUpdated;
      yield put(updateTagAC.done({
        params: {teamId, id, name, color},
        result: {__typename: 'UpdateTagPayload', tag: event.tagUpdated},
      }));
      break;
    }
    case 'TagDeletedEvent': {
      yield put(deleteTagAC.done({
        params: {teamId, id: event.tagDeleted},
        result: {__typename: 'DeleteTagPayload', tagId: event.tagDeleted},
      }));
      break;
    }
    case 'RoleAdded2Event': {
      if (teamId) {
        const { name, privileges } = event.roleAdded2;
        yield put(createCustomRoleAC.done({
          params: {teamId, name, privileges},
          result: {__typename: 'CreateCustomRolePayload', role: event.roleAdded2},
        }));
      }
      break;
    }
    case 'FolderAddedEvent': {
      if (teamId) {
        yield put(folderAddedAC(event.folderAdded));
      }
      break;
    }
    case 'FolderUpdatedEvent': {
      if (teamId) {
        yield put(folderUpdatedAC(event.folderUpdated));
      }
      break;
    }
    case 'FolderDeletedEvent': {
      if (teamId) {
        yield put(folderDeletedAC(event.folderDeleted));
      }
      break;
    }
    case 'RoleUpdated2Event': {
      if (teamId) {
        const { name, privileges, id } = event.roleUpdated2;
        yield put(updateCustomRoleAC.done({
          params: {teamId, name, privileges, roleId: id},
          result: {__typename: 'UpdateCustomRolePayload', role: event.roleUpdated2},
        }));
      }
      break;
    }
    case 'RoleDeletedEvent': {
      if (teamId) {
        yield put(deleteRoleAC.done({
          params: {teamId, id: event.roleDeleted},
          result: {__typename: 'DeleteRole2Payload', id: event.roleDeleted},
        }));
      }
      break;
    }
    case 'InvitationAddedEvent': {
      yield put(invitationAddedAC(event.invitationAdded));
      break;
    }
    case 'InvitationUpdatedEvent': {
      // TODO: when do we ever update invitations?
      break;
    }
    case 'InvitationDeletedEvent': {
      if (teamId) {
        yield put(invitationDeletedAC({teamId, invitationDeleted: event.invitationDeleted}));
      }
      break;
    }
    case 'MemberAddedEvent': {
      if (teamId) {
        const team = yield select(getTeamById, teamId);
        const teamName = team ? team.name : '';
        yield put(memberAddedAC({teamId, memberAdded: event.memberAdded}));
      }
      break;
    }
    case 'MemberUpdatedEvent': {
      if (teamId) {
        yield put(memberUpdatedAC({teamId, memberUpdated: event.memberUpdated}));
      }
      break;
    }
    case 'MemberDeletedEvent': {
      if (teamId) {
        const memberDict: ReturnType<typeof getTeamMemberDict> = yield select(getTeamMemberDict);
        const team = yield select(getTeamById, teamId);
        const teamName = team ? team.name : '';
        const member: Optional<GetMembersQuery_queryMembers_edges> = memberDict[event.memberDeleted];
        const memberName = member && member.user.alias || '';
        yield put(memberDeletedAC({teamId, memberDeleted: event.memberDeleted}));
      }
      break;
    }
  }
}

function* watchSubscribeToEvents(): SagaIterator {
  yield takeEvery(subscribeToEventsAC, function* (action: Action<EventsSubscriptionVariables>) {
    const { teamId } = action.payload;
    if (teamId) {
      if (eventTasks.has(teamId)) { return; } // already subscribed

      const task: Task = yield fork(subscribeEvents, action);

      // add the task to the list of tasks we need to cancel when we logout
      eventTasks.set(teamId, task);
    }
  });
}

function* unsubscribeEvents(action: Action<EventsSubscriptionVariables>): SagaIterator {
  const { teamId } = action.payload;
  if (!teamId) { return; }
  const task = eventTasks.get(teamId);
  if (task) {
    eventTasks.delete(teamId);
    yield cancel(task);
  }
}

function* watchUnsubscribeToEvents(): SagaIterator {
  yield takeEvery(unsubscribeToEventsAC, function* (action: Action<EventsSubscriptionVariables>) {
    yield call(unsubscribeEvents, action);
  });
}

function createVideoEventChannel(
  {client, variables}: ISubscriptionOptions<VideoEventsSubscriptionVariables>,
): EventChannel<VideoEventsSubscription_videoEvents> {
  return eventChannel<VideoEventsSubscription_videoEvents>((emit) => {
    const obs = client.subscribe({
      query: VideoEvents_Subscription,
      variables,
    });
    const sub = obs.subscribe({
      next: (result: ApolloQueryResult<VideoEventsSubscription>) => {
        emit(result.data.videoEvents);
      },
      complete: () => {
        emit(END);
      },
    });
    return () => {
      sub.unsubscribe();
    };
  });
}

function* subscribeVideoEvents(action: Action<VideoEventsSubscriptionVariables>): SagaIterator {
  const { client, dispose }: IClient = yield getContext('apollo');
  const { payload: params } = action;
  const chan: EventChannel<VideoEventsSubscription_videoEvents> = createVideoEventChannel({client, variables: params});
  try {
    while (true) {
      const event: VideoEventsSubscription_videoEvents = yield take(chan);
      try {
        yield call(handleVideoEvent, event, params);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
  } finally {
    if (yield cancelled()) {
      chan.close();
    }
  }
}

function* handleVideoEvent(event: VideoEventsSubscription_videoEvents, payload: {videoId: ID}): SagaIterator {
  switch (event.__typename) {
    case 'CommentAddedEvent':
      yield put(commentAddedAC({params: payload, result: event.commentAdded}));
      break;
    case 'CommentUpdatedEvent':
      yield put(commentUpdatedAC({params: payload, result: event.commentUpdated}));
      break;
  }
}

function* watchSubscribeToVideoEvents(): SagaIterator {
  yield takeEvery(subscribeToVideoEventsAC, function* (action: Action<{videoId: ID}>) {
    const { videoId } = action.payload;
    if (videoEventTasks.has(videoId)) { return; } // already subscribed

    const task: Task = yield fork(subscribeVideoEvents, action);

    // add the task to the list of tasks we need to cancel when we logout
    videoEventTasks.set(videoId, task);
  });
}

function* unsubscribeVideoEvents(action: Action<{videoId: ID}>): SagaIterator {
  const { videoId } = action.payload;
  const task = videoEventTasks.get(videoId);
  if (task) {
    videoEventTasks.delete(videoId);
    yield cancel(task);
  }
}

function* watchUnsubscribeToVideoEvents(): SagaIterator {
  yield takeEvery(unsubscribeToVideoEventsAC, function* (action: Action<{videoId: ID}>) {
    yield call(unsubscribeVideoEvents, action);
  });
}

function createUserEventChannel(
  {client, variables}: ISubscriptionOptions<void>,
): EventChannel<UserEventsSubscription_userEvents> {
  return eventChannel<UserEventsSubscription_userEvents>((emit) => {
    const obs = client.subscribe({
      query: UserEvents_Subscription,
    });
    const sub = obs.subscribe({
      next: (result: ApolloQueryResult<UserEventsSubscription>) => {
        emit(result.data.userEvents);
      },
      complete: () => {
        emit(END);
      },
    });
    return () => {
      sub.unsubscribe();
    };
  });
}

function* subscribeUserEvents(): SagaIterator {
  const { client, dispose }: IClient = yield getContext('apollo');
  const chan: EventChannel<UserEventsSubscription_userEvents> = yield call(
    createUserEventChannel,
    {client, variables: undefined},
  );
  try {
    while (true) {
      const event: UserEventsSubscription_userEvents = yield take(chan);
      try {
        yield call(handleUserEvent, event);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
  } finally {
    if (yield cancelled()) {
      chan.close();
    }
  }
}

function* selectOrFetchVideoById(videoId: ID): SagaIterator<ReturnType<typeof getVideoById>> {
  const video: ReturnType<typeof getVideoById> = yield select(getVideoById, videoId);
  if (video) {
    return video;
  }

  const fetchStatus: ReturnType<typeof getVideoFetchStatusById> = yield select(getVideoFetchStatusById, videoId);
  if (!fetchStatus?.fetching) {
    yield put(fetchVideosByIdAC.started({ id: videoId }));
  }

  while (true) {
    const action: ActionFromActionCreator<
      typeof fetchVideosByIdAC.done | typeof fetchVideosByIdAC.failed
    > = yield take([
      fetchVideosByIdAC.done,
      fetchVideosByIdAC.failed,
    ]);

    if (action.payload.params.id === videoId) {
      continue;
    }

    if (fetchVideosByIdAC.failed.match(action)) {
      return;
    }

    return action.payload.result || undefined;
  }
}

function* handleVideoOrCommentNotificationAddedEvent(
  {
    video: { id: videoId },
    team: { id: teamId },
  }: Exclude<NotificationFragment, NotificationFragment_MemberNotification>,
) {
  const video: SagaReturnType<typeof selectOrFetchVideoById> = yield call(selectOrFetchVideoById, videoId);
  if (!video) {
    return;
  }

  if (video.division) {
    yield put(fetchUnreadFolderNotificationsAC.started({
      teamId,
      folderId: video.division.id,
    }));
  }

  if (video.directory) {
    yield put(fetchUnreadFolderNotificationsAC.started({
      teamId,
      folderId: video.directory.id,
    }));
  }
}

function* handleUserEvent(event: UserEventsSubscription_userEvents): SagaIterator {
  switch (event.__typename) {
    case 'SelfUpdatedEvent':
      yield put(selfUpdatedAC(event.me));
      break;
    case 'TeamAddedEvent':
      yield put(teamAddedAC(event.teamAdded));
      break;
    case 'TeamUpdatedEvent':
      yield put(teamUpdatedAC(event.teamUpdated));
      break;
    case 'TeamDeletedEvent':
      yield put(teamDeletedAC(event.teamDeleted));
      break;
    case 'InvitationAddedEvent':
      yield put(invitationReceivedAC(event.invitationAdded));
      break;
    case 'InvitationDeletedEvent':
      yield put(invitationRevokedAC(event.invitationDeleted));
      break;
    case 'NotificationAddedEvent':
      yield put(notificationAddedAC(event.notificationAdded));
      yield put(fetchUnreadNotificationsAC.started());
      yield put(fetchUnreadTeamNotificationsAC.started({teamId: event.notificationAdded.team.id}));
      if (event.notificationAdded.__typename !== 'MemberNotification') {
        yield spawn(handleVideoOrCommentNotificationAddedEvent, event.notificationAdded);
      }
      break;
    case 'NotificationDeletedEvent':
      yield put(notificationDeletedAC(event.notificationDeleted));
      break;
    case 'NotificationUpdatedEvent':
      yield put(notificationUpdatedAC(event.notificationUpdated));
      break;
  }
}

function* watchSubscribeToUserEvents(): SagaIterator {
  yield takeEvery(subscribeToUserEventsAC, function* () {
    if (!userEventTask) {
      userEventTask = yield fork(subscribeUserEvents);
    }
  });
}

function* unsubscribeUserEvents(): SagaIterator {
  if (userEventTask) {
    yield cancel(userEventTask);
  }
  userEventTask = undefined;
}

function* watchUnsubscribeToUserEvents(): SagaIterator {
  yield takeEvery(unsubscribeToUserEventsAC, function* () {
    yield call(unsubscribeUserEvents);
  });
}

function* watchLogout(): SagaIterator {
  yield takeEvery(logoutAsyncAC.started, function* () {
    yield cancel(Array.from(eventTasks.values()));
    eventTasks.clear();

    yield cancel(Array.from(videoEventTasks.values()));
    videoEventTasks.clear();

    yield call(unsubscribeUserEvents);
  });
}

export default function* eventSaga() {
  yield all([
    watchSubscribeToEvents(),
    watchUnsubscribeToEvents(),
    watchSubscribeToVideoEvents(),
    watchUnsubscribeToVideoEvents(),
    watchSubscribeToUserEvents(),
    watchUnsubscribeToUserEvents(),
    watchLogout(),
  ]);
}

const eventTasks = new Map<ID, Task>();
const videoEventTasks = new Map<ID, Task>();
let userEventTask: Optional<Task>;

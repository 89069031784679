import { extname } from 'path';

import { VideoFragment } from './../apollo/fragments/types/VideoFragment';

export function makeDownloadFromVideo(video?: VideoFragment): {
  url: string;
  name: string;
} | undefined {
  if (!video) {
    return;
  }
  const urlString = video.streamUrls?.find((url: string) => !url.endsWith('.m3u8'));
  if (!urlString) {
    const m3u8 = video.streamUrls?.find((url: string) => url.endsWith('.m3u8'));
    if (m3u8) {
      return m3u8.replace(/\.m3u8$/, '.mp4');
    }

    return;
  }

  const url = new URL(urlString);
  const realext = extname(url.pathname);
  url.search += (url.search ? '&' : '?') + 'download=1';
  return {
    url: url.toString(),
    name: video.name.substring(0, video.name.length - extname(video.name).length) + realext,
  };
};

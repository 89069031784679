import gql from 'graphql-tag';

import { DirectoryFragment, InvitationFragment, MemberFragment, RoleInterfaceFragment, TagFragment, VideoFragment } from '../fragments';

export const Events_Subscription = gql`
    subscription EventsSubscription(
        $teamId: ID
    ) {
        events(
            teamId: $teamId
        ) {
            __typename
            ...on VideoAddedEvent {
                videoAdded {
                    ...VideoFragment
                }
            }
            ...on VideoUpdatedEvent {
                videoUpdated {
                    ...VideoFragment
                }
            }
            ...on VideoDeletedEvent {
                videoDeleted
            }
            ...on TagAddedEvent {
                tagAdded {
                    ...TagFragment
                }
            }
            ...on TagUpdatedEvent {
                tagUpdated {
                    ...TagFragment
                }
            }
            ...on TagDeletedEvent {
                tagDeleted
            }
            ...on RoleAdded2Event {
                roleAdded2 {
                    ...RoleInterfaceFragment
                }
            }
            ...on RoleUpdated2Event {
                roleUpdated2 {
                    ...RoleInterfaceFragment
                }
            }
            ...on RoleDeletedEvent {
                roleDeleted
            }
            ...on InvitationAddedEvent {
                invitationAdded {
                    ...InvitationFragment
                }
            }
            ...on InvitationUpdatedEvent {
                invitationUpdated {
                    ...InvitationFragment
                }
            }
            ...on InvitationDeletedEvent {
                invitationDeleted
            }
            ...on MemberAddedEvent {
                memberAdded {
                    ...MemberFragment
                }
            }
            ...on MemberUpdatedEvent {
                memberUpdated {
                    ...MemberFragment
                }
            }
            ...on MemberDeletedEvent {
                memberDeleted
            }
            ...on FolderAddedEvent {
                folderAdded {
                    ...DirectoryFragment
                }
            }
            ...on FolderUpdatedEvent {
                folderUpdated {
                    ...DirectoryFragment
                }
            }
            ...on FolderDeletedEvent {
                folderDeleted
            }
        }
    }
    ${VideoFragment}
    ${TagFragment}
    ${RoleInterfaceFragment}
    ${InvitationFragment}
    ${MemberFragment}
    ${DirectoryFragment}
`;
